import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import {
  UtilsService,
  CrowdWalletService,
  ErrorService,
  LoggingService,
  NetworksService,
  ServerErrorInterceptor,
  Web3Service,
  SocketService,
  StorageService,
  PermissionGuard,
  JobService,
  InitializeService,
  TimeService,
  NftService,
  TokensService,
  RunService,
  QRCodeService,
  CronService,
  ResponsiveService,
  PortfolioService,
  MiniAppService,
  SharedStateService
} from './shared/services';
import { PriceService } from '@crowdswap/constant';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScanModalComponent } from './shared/components/scan-modal/scan-modal.component';
import { SpeechService } from './shared/services/speech.service';

function initializeAppFactory(
  initializeService: InitializeService
): () => Promise<void> {
  return async () => {
    await initializeService.Initilize();
  };
}

@NgModule({
  declarations: [AppComponent, ScanModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    ZXingScannerModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    provideAnimationsAsync(),
    provideHttpClient(),
    { provide: PriceService, useValue: new PriceService() },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [InitializeService],
      multi: true
    },
    UtilsService,
    ErrorService,
    LoggingService,
    NetworksService,
    ServerErrorInterceptor,
    CrowdWalletService,
    Web3Service,
    SocketService,
    StorageService,
    PermissionGuard,
    NftService,
    TokensService,
    RunService,
    JobService,
    SpeechService,
    InitializeService,
    TimeService,
    QRCodeService,
    CronService,
    ResponsiveService,
    PortfolioService,
    MiniAppService,
    SharedStateService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
